import React from 'react';
import ReactStopwatch from 'react-stopwatch';

import moment from 'moment';

const Stopwatch = (props) => (
  <ReactStopwatch
    seconds={props.expiryTimestamp.seconds()}
    minutes={props.expiryTimestamp.minutes()}
    hours={props.expiryTimestamp.hours()}
    render={({ formatted, hours, minutes, seconds }) => {
      const days = props.expiryTimestamp.days();
      console.log(days);
      return (
        <div style={{textAlign: 'center'}}>
        <div style={{fontSize: '3em'}}>
        <strong>{days ?? 0}</strong> days, <strong>{hours}</strong> hours, <strong>{minutes}</strong> minutes, and <strong>{seconds}</strong> seconds
        </div>
        </div>
      );
    }}
   />
)

export default function Time() {
  const end  = moment(new Date('2022-01-22T17:00:00'));
  const today  = moment(new Date());

  const duration = moment.duration(today.diff(end));

  return (
    <div>
      <Stopwatch expiryTimestamp={duration} />
    </div>
  );
}