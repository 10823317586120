import './App.css';
import image from './static/roblox_logo_black.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Time from './components/time';
import Losses from './components/losses';

function App() {
  return (
    <Container className="vertical">
      {/* <Row >
        <h1>Roblox isn't down. <a href="https://status.roblox.com/">Don't fear, my child.</a></h1>
      </Row > */}
      <Row>
      <img src={image} className="App-logo" alt="logo" />
      </Row>
      <Row >
        <h1>has been down for</h1>
      </Row >
      <Row >
        <Time/>
      </Row >
      <Row className="padded">
        <p>Considered going outside?</p>
      </Row>
    </Container>
  );
}

export default App;
