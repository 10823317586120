import React, { Component } from 'react';
import moment from 'moment';

function currencyFormat(num) {
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 
class Losses extends Component {
	constructor(props){
		super(props);
		this.end  = moment(new Date('2021-10-28T23:00:00'));
		const today = moment(new Date());
		const duration = moment.duration(today.diff(this.end));

		this.state = {
			losses: Math.floor(duration.asDays() * 5479452)
		};
	}
	render() {
		return(
			<div>
				<h4>Roblox has lost over <strong>{ currencyFormat(this.state.losses) }$</strong> in revenue</h4>
			</div>
		);
	}
	componentWillUnmount() {
		clearInterval(this.interval);
	}

	componentDidMount() {
		const today = moment(new Date());
		const duration = moment.duration(today.diff(this.end));
		console.log("ruan")
		this.interval = setInterval(() => this.setState({ losses: Math.floor(duration.asDays() * 5479452) }), 1000);
  	}
}

export default Losses;